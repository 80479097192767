<template>
  <freelancer-profile freelancer-id="my-profile" />
</template>

<script>
import FreelancerProfile from '~/components/Freelancer/Profile'

export default {
  components: {
    FreelancerProfile,
  },
}
</script>
